@import url('https://fonts.googleapis.com/css2?family=Caprasimo&family=Dancing+Script&family=Quicksand:wght@300&display=swap');


* {
    list-style-type: none;
    padding: 0;
    margin: 0;
    box-sizing: border-box;
    word-wrap: break-word;
    font-family: 'Quicksand', sans-serif;
}

body{
    /*background-color:black;*/
    color: white;
}

header{
    position: fixed;
    left:0;
    right:0;
    top : 0;
    height:50px;
    background:rgba(0,0,0,.9);
    z-index:1;
    line-height:50px;
}
nav {
    height: 100%;
}
nav button{
    display:none;
}
    nav>ul {
        display: flex;
        padding: 0;
        height: 100%;
        flex-direction: row;
        flex-wrap: nowrap;
        align-content: center;
        justify-content: center;
        align-items: center;
    }
        nav>ul li{
            height: 100%;
            margin: 0 20px;
            position:relative;
        }
        nav>ul li ul{
            position:absolute;
            top:99%;
            left:0;
            width:150px;
            background:black;
            display:none;
            height:auto;
        }
        nav>ul li:hover ul{
            display:block;
        }
            nav>ul li ul li {
                text-align: left;
                display: block;
                margin:0;
                padding: 10px 5px;
                line-height:18px;
            }
        nav>ul li ul li a{
            text-align:left;
            display:block;
        }
            nav li a {
                padding: 0 5px;
                color: #fff;
                text-decoration: none;
                display: inline-block;
                height: 100%;
            }
                nav li a:hover{
                    text-decoration:underline;
                }
                nav li a.active{
                    text-decoration:underline;
                }

@media screen and (max-width: 768px) {
    nav{
        line-height:30px;
    }
    nav button {
        display: block;
        background: none;
        border: none;
        height: 100%;
        font-size: 2em;
        width: 100%;
        color: white;
    }
    nav>ul {
        flex-direction: column;
        justify-content:flex-start;
        position:fixed;
        top:50px;
        left:0;
        right:0;
        z-index:100;
        background-color:black;
        overflow:hidden;
        height:0;
        transition:height ease-in-out .5s;
    }
    nav ul.showed{
        height:calc(100vh - 50px);
    }
        nav ul li{
            height: auto;
            margin: 0 20px;
            width:100%;
        }
        nav ul li a{
            display:block;
            height: auto;
        }

        nav ul.showed li ul{
            position:relative;
            display:block;
            top:0;
            left:0;
            width:100%;
        }
        nav ul.showed li ul li{
            padding-left:20px;
        }
}


#imageCitation {
    position: fixed;
    top: 0;
    bottom: 50vh;
    left: 0;
    right: 0;
    z-index: -1;
    background-color: black;
    width: 100%;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
}
.home #imageCitation {
    bottom: 0;
}
    #imageCitation .citation {
        height: 100%;
        vertical-align: middle;
        background-color: rgba(0,0,0,0.3);
        display: grid;
        justify-content: center;
        align-content: center;
        align-items: center;
        text-align: center;
        width: 100%;
        font-size: 2.3em;
        padding: 10px;
    }
@media screen and (max-width: 1919px) {
    #imageCitation .citation {
        font-size: 1.9em;
    }
}
@media screen and (max-width: 1024px) {
    #imageCitation .citation {
        font-size: 1.5em;
    }
}
@media screen and (max-width: 768px) {
    #imageCitation .citation {
        font-size: 1.1em;
    }
}
@media screen and (max-width: 400px) {
    #imageCitation .citation {
        font-size: 1.0em;
    }
}
main {
    position: relative;
    top: 50vh;
    left: 0;
    right: 0;
    min-height: 50vh;
    background-color: black;
    padding: 10px 10px 200px 10px;
}
@media screen and (max-width: 768px) {
    main {
        top: calc(50vh - 80px);
    }
}
.home main{
    top: 100vh;
}
main .content{
    max-width:1500px;
    margin:30px auto;
}
h2{
    margin-bottom:20px;
}
p{
    margin-bottom:10px;
}
p.specialSousH2{
    margin-top:-20px;
    margin-bottom:30px;
}

main a:visited{
    color:white;
}

.content ul li {
    padding-left: 10px;
    margin-bottom: 10px;
    list-style-type: disclosure-closed;
    list-style-position: inside;
}

footer {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    text-align: center;
    height: 200px;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
}
#contact {
    text-align: center;
}

.formulaire-image-list {
    display: flex;
    flex-wrap: nowrap;
    gap: 20px;
    width:100%;
    height:200px;
    background-color: rgba(255,255,255,.1);
    overflow:auto;
}

.formulaire-image-item {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 2px;
}

.formulaire-image-container {
    width: 150px;
    height: 150px;
    overflow: hidden;
    border: 1px solid #ccc;
    display: flex;
    align-items: center;
    justify-content: center;
}

    .formulaire-image-container img {
        width: 100%;
        height: 100%;
        object-fit: cover;
    }


.image-grid {
    display: flex;
    flex-wrap: wrap;
}

.grid-image {
    width: 25%;
    position: relative;
    overflow: hidden;
}

.image-wrapper {
    width: 100%;
    padding-bottom: 100%; /* Cr&eacute;e un carr&eacute; en utilisant le padding trick */
    position: relative;
}

    .image-wrapper img {
        position: absolute;
        width: 100%;
        height: 100%;
        object-fit: cover;
        transition: transform 0.3s;
    }

@media screen and (max-width: 1600px) {
    .grid-image {
        width: 33.33%;
    }
}
@media screen and (max-width: 1024px) {
    .grid-image {
        width: 50%;
    }
}
@media screen and (max-width: 768px) {
    .grid-image {
        width: 100%;
    }
}

.lightbox {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.9);
    z-index: 999;
}

.lightbox-content {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
}

.lightbox img {
    max-width: 90%;
    max-height: 90%;
}


.form-container {
    display: flex;
    justify-content: space-between;
    flex-wrap:nowrap;
    gap:10px;
    margin : 10px auto;
}

.form-column {
    flex: 1;
    display:flex;
    flex-direction:column;
    gap:10px;
}

.form-column input, .form-column textarea{
    padding:5px;
}
.form-column textarea{
    height:70px;
}
@media screen and (max-width: 768px) {
.form-container {
    flex-direction:column;
}
}